export const API_SUCCESS = 'API_SUCCESS';

export const PRICEOFFERLOADING = 'PRICEOFFERLOADING';
export const PRICEOFFERLOADED = 'PRICEOFFERLOADED';

export const CLAIMLOADING = 'CLAIMLOADING';
export const CLAIMLOADED = 'CLAIMLOADED';

export const CLAIMPARTSLOADING = 'CLAIMPARTSLOADING';
export const CLAIMPARTSLOADED = 'CLAIMPARTSLOADED';

export const REPORTLOADING = 'REPORTLOADING';
export const REPORTLOADED = 'REPORTLOADED';

export const PARTREMOVING = 'PARTREMOVING';
export const PARTREMOVED = 'PARTREMOVED';

export const IMAGEUPLOADING = 'IMAGEUPLOADING';
export const IMAGEUPLOADED = 'IMAGEUPLOADED';

export const STATUSUPDATING = 'STATUSUPDATING';
export const STATUSUPDATED = 'STATUSUPDATED';

export const VEGHICLEMODELLOADING = 'VEGHICLEMODELLOADING';
export const VEGHICLEMODELLOADED = 'VEGHICLEMODELLOADED';

export const VEGHICLESUBMODELLOADING = 'VEGHICLESUBMODELLOADING';
export const VEGHICLESUBMODELLOADED = 'VEGHICLESUBMODELLOADED';

export const POLICIESLOADING = 'POLICIESLOADING';
export const POLICIESLOADED = 'POLICIESLOADED';

export const PARTSLOADING = 'PARTSLOADING';
export const PARTSLOADED = 'PARTSLOADED';

export const ADDPARTSLOADING = 'ADDPARTSLOADING';
export const ADDPARTSLOADED = 'ADDPARTSLOADED';

export const CLAIMNEWTENDERPERIODLOADING = 'CLAIMNEWTENDERPERIODLOADING';
export const CLAIMNEWTENDERPERIODLOADED = 'CLAIMNEWTENDERPERIODLOADED';

export const IMAGEDELETING = 'IMAGEDELETING';
export const IMAGEDELETED = 'IMAGEDELETED';
export const IMAGEDELETEDFAILED = 'IMAGEDELETEDFAILED';

export const CLAIMSLISTSLOADED = 'CLAIMSLISTSLOADED';

export const PRICEACCEPTTING = 'PRICEACCEPTTING';
export const PRICEACCEPTED = 'PRICEACCEPTED';

export const FIXEDAMOUNTUPDATING = 'FIXEDAMOUNTUPDATING';
export const FIXEDAMOUNTUPDATED = 'FIXEDAMOUNTUPDATED';

export const PRICEAFTERDISCOUNTUPDATING = 'PRICEAFTERDISCOUNTUPDATING';
export const PRICEAFTERDISCOUNTUPDATED = 'PRICEAFTERDISCOUNTUPDATED';

export const PRICEOFFERUPDATING = 'PRICEOFFERUPDATING';
export const PRICEOFFERUPDATED = 'PRICEOFFERUPDATED';

export const PRICEOFFERDELETING = 'PRICEOFFERDELETING';
export const PRICEOFFERDELETED = 'PRICEOFFERDELETED';

export const SELLERSLINKSLOADING = 'SELLERSLINKSLOADING';
export const SELLERSLINKSLOADED = 'SELLERSLINKSLOADED';

export const SETPARTS = 'SETPARTS';